<template>
  <div class="planet">
    <div class="planet-con flex">
      <div class="planet-top">
        <img
          src="../../public/images/plantclose.png"
          alt=""
          class="closeicon"
          @click="$router.go(-1)"
        />
        <img src="../../public/images/planetimg5.png" alt="" class="topimg" />
        <img src="../../public/images/planetimg4.png" alt="" class="topimg1" />
      </div>
      <div class="planet-left">
        <div class="planet-left-icon">
          <img
            src="../../public/images/plantclose.png"
            alt=""
            @click="$router.go(-1)"
          />
        </div>
        <div class="planet-left-info">
          <div class="left-info-name">{{ info.eraName }}</div>
          <div class="left-info-list flex">
            <div class="list-info">
              <div>{{ $t("pool.desc14") }}</div>
              <p>{{ info.outCoinFullName }}</p>
            </div>
            <div class="list-info">
              <div>{{ $t("pool.desc15") }}</div>
              <p>#{{ info.planetId }}</p>
            </div>
            <div class="list-info">
              <div>{{ $t("pool.desc16") }}</div>
              <p>{{ info.saleCountLand }}/{{ info.totalLand }}</p>
            </div>
            <div class="list-info">
              <div>{{ $t("pool.desc17") }}</div>
              <p>{{ info.track }} - {{ info.no }}</p>
            </div>
            <div class="list-info">
              <div>{{ $t("pool.desc18") }}</div>
              <p>{{ info.iownCount }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="planet-center">
        <img src="../../public/images/planetimg5.png" alt="" />
      </div>
      <div class="planet-right">
        <div class="right-title">{{ $t("pool.desc19") }}</div>
        <div class="right-btn-list flex">
          <div
            class="btn-info flexcenter"
            :class="{ actcolor: act == item }"
            v-for="item in 4"
            :key="item.id"
            v-html="$t('pool.desc20', { n1: 5, n2: 'DAOT', n3: item })"
          ></div>
          <div class="btn-info flexcenter">{{ $t("pool.desc21") }}</div>
          <div class="btn-info flexcenter">{{ $t("pool.desc22") }}</div>
        </div>
        <div class="right-num flex">
          <div class="right-num-data">
            <div class="num-data-name">{{ $t("pool.desc23") }}</div>
            <div class="total-num flex">
              <div><img src="../../public/images/addnum1.png" alt="" /></div>
              <input type="tel" v-model="num" />
              <div><img src="../../public/images/addnum.png" alt="" /></div>
            </div>
          </div>
          <div class="right-num-data">
            <div class="num-data-name">{{ $t("pool.desc24") }}</div>
            <div class="totals-price">
              {{ totalsMoney }} {{ info.coinSymbol }}
            </div>
          </div>
        </div>
        <div class="right-btn flexcenter">
          {{ $t("pool.desc25") }}
        </div>
      </div>
    </div>
    <!-- 购买 -->
    <el-dialog
      title="提示"
      v-model="dialogBackset"
      width="30%"
      :append-to-body="true"
      class="modalinfo"
      :modal="false"
    >
      <div class="modalinfo-con">
        <div class="modalinfo-con-title">
          {{ $t("planet.desc29") }}
          <img
            src="../../public/images/closemodal.png"
            alt=""
            @click="dialogBackset = false"
          />
        </div>
        <div
          class="mining-tips"
          v-html="$t('planet.desc30', { n1: '100 USDT' })"
        ></div>
        <div class="mining-projuct flex">
          <img src="../../public/images/planetimg2.png" alt="" />
          <div class="projuct-info">
            <div>石器时代土地</div>
            <p>X999</p>
          </div>
        </div>
        <div class="modalinfo-con-address">
          <div class="address-name">{{ $t("home.desc7") }}</div>
          <div class="address-input">
            <input
              type="text"
              v-model="address"
              :placeholder="$t('planet.desc17')"
            />
          </div>
        </div>
        <div class="modalinfo-con-address margintop">
          <div class="address-name">{{ $t("home.desc8") }}</div>
          <div class="address-input">
            <input
              type="text"
              v-model="address"
              :placeholder="$t('planet.desc18')"
            />
          </div>
        </div>
        <div class="modalinfo-con-btn flex">
          <div class="flexcenter" @click="dialogBackset = false">
            {{ $t("planet.desc31") }}
          </div>
          <div class="flexcenter">{{ $t("planet.desc32") }}</div>
        </div>
      </div>
    </el-dialog>

    <PageLoading v-model:show="showLoading" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      act: 1,
      num: 1,
      dialogBackset: false,
      address: "",
      info: {},
      showLoading: true,
    };
  },
  computed: {
    totalsMoney() {
      let totals = 0;
      if (this.info.priceAmount != undefined) {
        totals = parseFloat(this.info.priceAmount) * this.num;
      }
      return totals;
    },
  },
  async mounted() {
    try {
      const res = await this.$post(this.URL.planet.info, {
        planetId: this.$route.query.id,
      });
      if (res.code == 0) {
        this.info = res.data;
      } else {
        this.$message.error(res.message);
      }
      this.showLoading = false;
    } catch (error) {
      this.showLoading = false;
    }
  },
};
</script>

<style lang="less" scoped>
.planet {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: url("../../public/images/planetbg1.png") top no-repeat;
  background-size: 100% 100%;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../../public/images/planetbg2.png") top no-repeat;
    background-size: 100% 100%;
  }

  .planet-con {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 1240px;
    max-height: 100vh;
    overflow-y: scroll;
    padding: 95px 0 40px;
    z-index: 6;

    &::-webkit-scrollbar {
      display: none;
    }
    .planet-top {
      display: none;
    }
    .planet-left {
      flex: 0 0 280px;
      width: 280px;
      margin-top: 47px;

      .planet-left-icon {
        width: 44px;
        height: 44px;
        cursor: pointer;

        img {
          width: 44px;
          height: 44px;
        }
      }

      .planet-left-info {
        margin-top: 85px;

        .left-info-name {
          margin-bottom: 40px;
          font-size: 40px;
          color: #ffffff;
        }

        .left-info-list {
          flex-wrap: wrap;

          .list-info {
            flex: 0 0 50%;
            margin-bottom: 24px;
            font-size: 12px;
            color: rgba(255, 255, 255, 0.8);

            p {
              margin-top: 10px;
              font-size: 24px;
              color: #ffffff;
            }
          }
        }
      }
    }

    .planet-center {
      position: relative;
      flex: 0 0 560px;
      width: 560px;
      margin-top: 78px;

      &::after {
        content: "";
        position: absolute;
        top: -28px;
        left: -35px;
        width: 102px;
        height: 102px;
        background: url("../../public/images/planetimg4.png") center no-repeat;
        background-size: 100% 100%;
      }

      img {
        width: 560px;
        height: 473px;
      }
    }

    .planet-right {
      flex: 0 0 348px;
      padding-top: 91px;
      margin-left: 52px;

      .right-title {
        font-size: 24px;
        color: #ffffff;
      }

      .right-btn-list {
        flex-wrap: wrap;
        padding: 20px 0 6px;

        .btn-info {
          flex: 0 0 164px;
          height: 50px;
          margin: 0 19px 19px 0;
          border: 1px solid #555555;
          box-sizing: border-box;
          cursor: pointer;
          font-size: 14px;
          color: #bdbdbd;

          &:nth-child(2n) {
            margin-right: 0;
          }
        }

        .actcolor {
          border: 1px solid #ffe279;
          color: #fff;
        }
      }

      .right-num {
        padding: 24px;
        margin-bottom: 25px;
        background: rgba(110, 110, 110, 0.16);

        .right-num-data {
          flex: 0 0 110px;

          .num-data-name {
            font-size: 12px;
            margin-bottom: 14px;
            color: rgba(255, 255, 255, 0.8);
          }

          &:last-child {
            flex: 1;
            margin-left: 32px;
            border-left: 1px solid rgba(255, 255, 255, 0.2);
            box-sizing: border-box;

            .num-data-name {
              padding-left: 27px;
            }
          }

          .total-num {
            flex: 0 0 110px;
            height: 32px;
            border: 1px solid #444;
            box-sizing: border-box;

            div {
              flex: 0 0 30px;
              width: 30px;
              height: 30px;
              text-align: center;
              cursor: pointer;

              img {
                width: 10px;
                height: 10px;
                margin-top: 9px;
              }
            }

            input {
              flex: 0 0 48px;
              width: 30px;
              height: 30px;
              font-size: 13px;
              color: #ffffff;
              text-align: center;
              border-left: 1px solid #444;
              border-right: 1px solid #444;
              box-sizing: border-box;
              border-radius: 0;
            }
          }

          .totals-price {
            padding-left: 27px;
            font-size: 20px;
            color: #ffffff;
            font-weight: 700;
          }
        }
      }

      .right-btn {
        margin-top: 5px;
        width: 155px;
        height: 50px;
        font-size: 18px;
        color: #000000;
        background: url("../../public/images/planetbtnbg.png") center no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 1200px) {
  .planet {
    background: #000;
    padding-bottom: 20px;

    &::after {
      position: inherit;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      background: none;
    }

    .planet-con {
      position: inherit;
      top: 0;
      left: 0;
      transform: translateX(0);
      width: inherit;
      padding: 68px 15px 0;
      flex-wrap: wrap;
      z-index: inherit;
      max-height: inherit;
      overflow-y: inherit;

      .planet-left,
      .planet-right {
        flex: 0 0 100%;
        margin: 0;
      }
      .planet-left {
        height: auto;
        .planet-left-icon {
          display: none;
        }
        .planet-left-info {
          margin-top: 0;
          .left-info-name {
            font-size: 24px;
            margin-bottom: 24px;
          }
          .left-info-list {
            .list-info {
              flex: 0 0 33.3%;
              font-size: 12px;
              p {
                margin-top: 6px;
                font-size: 20px;
              }
              &:nth-child(3n) {
                text-align: right;
              }
              &:nth-child(2),
              &:nth-child(5) {
                text-align: center;
              }
            }
          }
        }
      }
      .planet-center {
        display: none;
      }
      .planet-right {
        padding-top: 16px;
        .right-title {
          font-size: 16px;
        }
        .right-btn-list {
          padding: 17px 0 0;
          .btn-info {
            flex: 0 0 48%;
            margin-right: 4%;
            margin-bottom: 14px;
            &:nth-child(2n) {
              margin-right: 0;
            }
          }
        }
        .right-btn {
          width: 100%;
          height: 54px;
          background: url("../../public/images/palntbtnicon.svg") center
            no-repeat;
          background-size: 100% 100%;
        }
      }
      .planet-top {
        position: relative;
        display: block;
        flex: 0 0 100%;
        padding: 47px 15px 33px;
        .topimg {
          width: 100%;
          height: 262px;
          object-fit: cover;
        }
        .closeicon {
          position: absolute;
          top: 27px;
          right: 0;
          width: 42px;
          height: 42px;
        }
        .topimg1 {
          position: absolute;
          top: 20px;
          left: 0;
          width: 62px;
          height: 62px;
        }
      }
    }
  }
}
</style>
